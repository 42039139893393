<template>
  <div
    class="main-single-page welcome"
    tabindex="0"
    role="button"
    @click="setMenuStatus(true)"
    v-if="
      landingPagePreferenceUrl === 'r_home' ||
        $route.name === 'r_home-alt' ||
        !subdomain
    "
  >
    <span class="open-main-menu">
      <svg-icon icon="menu"></svg-icon>
    </span>
    <large-logo
      :welcome="true"
      :welcomeText="displayLabelName('global.messages.welcome')"
    ></large-logo>
  </div>
</template>

<script>
import LargeLogo from "@/components/global/LargeLogo";
import { mapActions, mapState } from "vuex";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "Welcome",
  computed: {
    ...mapState("preferences", ["landingPagePreferenceUrl"]),
    subdomain() {
      return getSubdomain();
    },
  },
  created() {
    if (
      this.landingPagePreferenceUrl &&
      this.landingPagePreferenceUrl !== "r_home" &&
      this.$route.name !== "r_home-alt"
    ) {
      this.$router.push({
        name: this.landingPagePreferenceUrl,
      });
    } else {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true,
      });
    }
  },
  methods: {
    ...mapActions("global", ["setMenuStatus"]),
  },
  components: {
    LargeLogo,
  },
  watch: {
    landingPagePreferenceUrl(val) {
      if (val && val !== "r_home" && this.$route.name !== "r_home-alt") {
        this.$router.push({
          name: val,
        });
      }
    },
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true,
    });
  },
};
</script>
