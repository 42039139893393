<template>
  <section v-if="!welcome" class="logo large-logo">
    <img
      class="large-logo-img company-img"
      v-if="companyLogoData"
      :src="companyLogoData.url"
      alt="Logo"
      ref="largelogo"
      @load="setImageDimensionsLogin"
    />
    <img
      v-else
      class="large-logo-img clebex-img"
      src="../../assets/logo@2x.png"
      alt="Clebex logo"
      style="max-width: 70% !important;"
      :class="{ hidden: !noCompanyLogo }"
    />
    <powered-by-clebex v-if="!noCompanyLogo" />
  </section>
  <img
    class="large-logo-img company-img"
    v-if="welcome && companyLogoData"
    :src="companyLogoData.url"
    alt="Logo"
    ref="largelogo"
    @load="setImageDimensionsHome"
  />
  <img
    v-else-if="welcome"
    class="large-logo-img clebex-img"
    src="../../assets/logo@2x.png"
    alt="Clebex logo"
    style="max-width: 35% !important;"
    :class="{ hidden: !noCompanyLogo }"
  />
  <powered-by-clebex v-if="welcome && !noCompanyLogo" />
  <br /><br />
  <h1 v-if="welcome">{{ welcomeText }}</h1>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "large-logo",
  components: {
    PoweredByClebex: defineAsyncComponent(() =>
      import("@/components/global/PoweredByClebex")
    ),
  },
  data() {
    return {
      savedLogo: null,
      noCompanyLogo: false,
    };
  },
  computed: {
    ...mapState("companyProfile", ["companyLogo"]),
    companyLogoData() {
      if (this.companyLogo && this.companyLogo.data) {
        return this.companyLogo.data;
      }
      return null;
    },
  },
  created() {
    if (getSubdomain()) {
      this.getCompanyLogo();
    } else {
      this.noCompanyLogo = true;
    }
  },
  methods: {
    ...mapActions("companyProfile", ["getCompanyLogo"]),
    setImageDimensionsLogin(event) {
      console.log("Login");
      // Access image dimensions using 'naturalWidth' and 'naturalHeight' properties
      if (event.target.naturalWidth >= event.target.naturalHeight) {
        this.$refs.largelogo.style.maxWidth = "100%";
        this.$refs.largelogo.style.height = "auto";
      } else {
        this.$refs.largelogo.style.width = "auto";
        this.$refs.largelogo.style.maxHeight = "70%";
      }
    },
    setImageDimensionsHome(event) {
      // Access image dimensions using 'naturalWidth' and 'naturalHeight' properties
      console.log("Home");
      if (event.target.naturalWidth >= event.target.naturalHeight) {
        this.$refs.largelogo.style.maxWidth = "80%";
        this.$refs.largelogo.style.height = "auto";
      } else {
        this.$refs.largelogo.style.width = "auto";
        this.$refs.largelogo.style.maxHeight = "50%";
      }
    },
  },
  watch: {
    companyLogo: {
      handler(val) {
        if (typeof val === "string" && !val) {
          this.noCompanyLogo = true;
        }
      },
      immediate: true,
    },
  },
  props: {
    welcome: {
      type: Boolean,
      required: false,
      default: false,
    },
    welcomeText: {
      type: Text,
      required: false,
      default: "Welcome",
    },
  },
};
</script>
